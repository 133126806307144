<template>
  <div class="corpusdetail-msg">
    <van-nav-bar class="van-nav-bar--fixed" title="详细" left-arrow  right-text="开奖记录"  @click-left="onClickLeft"  @click-right="onClickRight"></van-nav-bar>

    <div class="vant-swiper-wrap">
        <div class="van-swipe">
            <div class="van-swipe__track" style="transition-duration: 0ms; transform: translateX(0px);">
                <div class="van-swipe-item swipe-item-img" style="width: 480px;"><img style="width: 480px;"
                        src="/img/20220403200039--1715100889.jpg">
                </div>
            </div>
        </div><i class="close van-icon van-icon-clear" style="color: rgb(255, 255, 255); font-size: 16px;">
    
        </i>
    </div>
    <KaiJiangShow :lotto_id="lotto_id" />

        <div class="detail-body">
    <div>
        <div class="van-sticky">
            <div class="detail-body-box">
                <div class="cell-top clearfix">
                    <div class="user-img">
                        <div class="left-image">
                            <div class="van-image van-image--round"><img
                                    :src="data.avatar"
                                    class="van-image__img"></div>
                        </div>
                        <div class="user-info">
                            <div class="name">
                                {{ data.username }}
                                <span class="reportInfo"></span>
                            </div>
                            <div class="time">{{ data.date }}</div>
                        </div>
                        <!---->
                    </div>
                         
                </div>
               
            </div>
        </div>
    </div>
    <!----><span class="reportInfo"></span>
    <div class="content-box has-read">{{ data.title }}</div>
    <!---->
    <div class="desc-body-box">

        <p>
             {{ data.content }}
        </p>

        <p v-if="data.smallimages.length>0">
             <van-image v-for="(image,index) in data.smallimages" :key="index" :src="siteurl+image" @click="onPreview(siteurl+image)"/>
        </p>

    </div>

    </div>
    

  </div>
</template>

<script>
import KaiJiangShow from '@/components/KaiJiangShow.vue'
import { ImagePreview } from 'vant';
export default {
    components: {
        KaiJiangShow
  },
  data() {
    return {
      category_id:1,
      year_id:1,
      lotto_id:2,
      active: 'picture',
      list:[],
      data:[],
      loading: false,
      finished: false,
      iserror:false,
      refreshing:false,
      page:0,
      message:'',
      value:'',
      iscolour: 0,
      id:0
    };
  },
  mounted() {
        //this.active='picture';
        this.lotto_id= parseInt(this.$route.query.lotto_id) ? parseInt(this.$route.query.lotto_id)  : 2;
        this.id= parseInt(this.$route.query.id) ? parseInt(this.$route.query.id)  : 1;
        this.getData();
    },
    methods: {
        onPreview(url){
            ImagePreview({
            images: [url],
            closeable: true,
            });
        },
        onClickLeft() {
            // 返回上一级页面
            this.$router.back();
        },
        onClickRight() {
            this.$router.push({ path: '/kaijiang?lotto_id='+this.lotto_id});
        },
        goToUrl(id){
            this.$router.push({ path: '/corpus/list?id='+id});
        },
        getData() {

                 this.$http
                    .get("api/corpus/show?id="+this.id+'&lotto_id='+this.lotto_id)
                    .then(function (response) {
                        
                        if (response.data.code == "ok") {

                            this.data= response.data.data.data;

                            this.loading = false;
                       
                            //this.finished = true;
                        } else {
                            
                            this.iserror = true;
                            this.message = response.data.msg;
                        }
                        this.loading = false;
                  
                       // console.log(this.list);
                    });
    
          
        },
    }
}
</script>
<style>
.corpusdetaillist-msg .vant-swiper-wrap {
    margin-top: 50px;
}
.corpus-wrap .search-list {
    margin-bottom: 50px;
}
.van-pull-refresh {
    margin-bottom: 50px;
}
.corpusdetail-msg .detail-body .desc-body-box {
    margin-bottom: 50px;
}
.swipe-item-img {
    margin-top: 50px;
}
.corpusdetail-msg .block-lottery {
     margin-top: 0px;
}
</style>